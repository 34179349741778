import React from 'react';
import { useParams } from 'react-router-dom';
import NotFound from '../../containers/NotFound/NotFound';
import { checkValidSponsor } from '../../utils';

// Allow only valid sponsors
function AuthorizeSponsor({ children, toPath, ctaText }) {
  const { sponsor } = useParams();
  const isValidSponsor = checkValidSponsor(sponsor);
  if (!isValidSponsor) return <NotFound is404 toPath={toPath} ctaText={ctaText} />;
  return children;
}

export default AuthorizeSponsor;
