import { Box, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import CTA from '../../components/common/CTA/CTA';
import path from '../../routes/constants';

const NotFound = ({
  message, toPath, is404, ctaText,
}) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      minHeight: '50vh',
      padding: '1rem',
    }}
  >
    <Typography
      variant="h1"
      sx={{
        marginY: '100px',
        fontSize: {
          xs: `${is404 ? '8rem' : '1.5rem'}`,
          sm: `${is404 ? '12rem' : '2rem'}`,
          md: `${is404 ? '16rem' : '3.5rem'}`,
        },
      }}
    >
      {message}
    </Typography>
    <CTA to={toPath} variant="contained">
      <Typography variant="button" fontWeight={700}>
        {ctaText}
      </Typography>
    </CTA>
  </Box>
);

export default NotFound;

NotFound.propTypes = {
  message: PropTypes.string,
  toPath: PropTypes.string,
  ctaText: PropTypes.string,
  is404: PropTypes.bool,
};

NotFound.defaultProps = {
  message: '404',
  toPath: path.root,
  is404: false,
  ctaText: 'Enroll in kardiacare+',
};
