/* eslint-disable */
export const ENDPOINT = process.env.REACT_APP_ENDPOINT;
export const ENDPOINT_RAILS = process.env.REACT_APP_ENDPOINT_RAILS;
export const FORGOT_PASSWORD_URL = process.env.REACT_APP_FORGOT_PASSWORD_URL;
export const BASE = '/i/v1/kardiacareplus';
export const BASE_KCC = '/i/vi/kcc';
export const BASE_RAILS = '/api/v1';
export const USER_INTERNAL_BASE = '/i/v1'
export const pathConfig = {
  // Eligibility assessment
  ASSESSMENT: {
    path: '/enroll_web',
    method: 'POST',
  },
  // Creating new KC+ user
  CREATE_USER: {
    path: '/createKCPlusUser',
    method: 'POST',
  },
  // User enromment status
  ENROLL_STATUS: {
    path: '/enroll/status',
    method: 'GET',
  },
  LOGIN: {
    path: '/authenticate_web',
    method: 'POST',
  },
  // link existing user
  LINK_USER: {
    path: '/linkUser',
    method: 'POST',
  },
  // Shipping details form
  SHIPPING_DETAILS: {
    path: '/shippingDetails',
    method: 'POST',
  },
  // Profile form
  PROFILE_DETAILS: {
    path: '/completeProfile',
    method: 'POST',
  },
  CLAIM: {
    path: '/claim',
    method: 'POST',
  },
  VERIFY_ADDRESS: {
    path: '/verifyShippingAddress',
    method: 'POST',
  },
  USER_STATUS: {
    path: '/getUserAccountStatus',
    method: 'GET',
  },
};
