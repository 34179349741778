import React from 'react';
import PropTypes from 'prop-types';
import NotFound from '../../containers/NotFound/NotFound';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch() {
    // You can also log the error to an error reporting service
  }

  render() {
    const { hasError } = this.state;
    const { children, sponsor } = this.props;

    if (hasError) {
      // You can render any custom fallback UI
      <NotFound
        message="Oops..., Something went wrong"
        toPath={sponsor ? `/${sponsor}` : '/'}
      />;
    }

    return children;
  }
}

export default ErrorBoundary;

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  sponsor: PropTypes.string,
};

ErrorBoundary.defaultProps = {
  sponsor: '',
};
