import React from 'react';
import Facebook from '@mui/icons-material/Facebook';
import Instagram from '@mui/icons-material/Instagram';
import LinkedIn from '@mui/icons-material/LinkedIn';
import Twitter from '@mui/icons-material/Twitter';
import Grid from '@mui/material/Grid';
import CustomLink from '../common/CustomLink/CustomLink';
import { SOCIAL_LINKS } from '../../constants/constants';

const SocialIconBar = () => (
  <Grid container justifyContent="space-between" maxWidth="120px">
    <CustomLink
      text={<Twitter color="primary" />}
      url={SOCIAL_LINKS.TWITTER}
      native
      target="_blank"
    />
    <CustomLink
      text={<LinkedIn color="primary" />}
      url={SOCIAL_LINKS.LINKEDIN}
      native
      target="_blank"
    />
    <CustomLink
      text={<Instagram color="primary" />}
      url={SOCIAL_LINKS.INSTAGRAM}
      native
      target="_blank"
    />
    <CustomLink
      text={<Facebook color="primary" />}
      url={SOCIAL_LINKS.FACEBOOK}
      native
      target="_blank"
    />
  </Grid>
);

export default SocialIconBar;
