import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'inline-block',
    textDecoration: 'none',
    color: '#000',
  },
  underLine: {
    textDecoration: 'underline',
  },
  primaryLink: {
    color: theme.colors.link.primary,
  },
}));

const CustomLink = ({
  text,
  url,
  underline,
  primary,
  native,
  fontwight,
  ...restProps
}) => {
  const classes = useStyles();
  let classNames = `${classes.link}`;
  if (underline) {
    classNames += ` ${classes.underLine}`;
  }
  if (primary) {
    classNames += ` ${classes.primaryLink}`;
  }
  if (native) {
    return (
      // eslint-disable-next-line
      <a href={url} className={classNames} style={{fontWeight: fontwight}} {...restProps}>
        {text}
      </a>
    );
  }
  return (
    // eslint-disable-next-line
    <Link to={url} className={classNames} style={{fontWeight: fontwight}} {...restProps}>
      {text}
    </Link>
  );
};

export default CustomLink;
CustomLink.defaultProps = {
  text: 'Link',
  url: '#',
  underline: false,
  primary: false,
  native: false,
  fontwight: 600,
};
CustomLink.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  url: PropTypes.string,
  underline: PropTypes.bool,
  primary: PropTypes.bool,
  native: PropTypes.bool,
  fontwight: PropTypes.number,
};
