const path = {
  root: '/',
  sponsorRoot: '/:sponsor',
  assessment: '/assessment',
  enroll: '/enroll',
  confirmation: '/assessment/confirmation',
  success: '/enroll/success',
  create: '/create',
  login: '/login',
  notFound: '*',
};
export default path;
