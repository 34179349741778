import React, { lazy } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import AuthorizeSponsor from '../components/AuthorizeSponsor/AuthorizeSponsor';
import NotFound from '../containers/NotFound/NotFound';
import Layout from '../containers/Layout/Layout';
import path from './constants';
import withSuspanse from '../HOC/withSuspance';

const AssessmentForm = lazy(() => import('../components/AssessmentForm/AssessmentForm'));
const SuccessScreen = lazy(() => import('../containers/SuccessScreen/SuccessScreen'));
const AccountContainer = lazy(() => import('../containers/AccountContainer/AccountContainer'));
const LoginAccountContainer = lazy(() => import('../containers/LoginAccountContainer/LoginAccountContainer'));
const MainContainer = lazy(() => import('../containers/MainContainer/MainContainer'));
const ConfirmationScreen = lazy(() => import('../containers/ConfirmationScreenContainer/ConfirmationScreenContainer'));

let enrollmentData;
const storeEnrollmentData = (data) => {
  enrollmentData = data;
};
const getEnrollmentData = () => enrollmentData;

function RouteManager() {
  return (
    <Router>
      <Layout>
        <Routes>
          {/* can be removed the navigation route if there is more than one sponsor */}
          <Route path={path.root} element={<Navigate to="/omron" />} />
          <Route
            path={path.sponsorRoot}
            element={withSuspanse(<AuthorizeSponsor toPath="/" ctaText="Go to home"><MainContainer /></AuthorizeSponsor>)}
          />
          <Route
            path={`${path.sponsorRoot}${path.assessment}`}
            element={
              withSuspanse(
                <AuthorizeSponsor>
                  <AssessmentForm storeData={storeEnrollmentData} getData={getEnrollmentData} />
                </AuthorizeSponsor>,
              )
            }
          />
          <Route
            path={`${path.sponsorRoot}${path.confirmation}`}
            element={withSuspanse(<AuthorizeSponsor><ConfirmationScreen /></AuthorizeSponsor>)}
          />
          <Route
            path={`${path.sponsorRoot}${path.success}`}
            element={withSuspanse(<AuthorizeSponsor><SuccessScreen /></AuthorizeSponsor>)}
          />
          <Route
            path={`${path.sponsorRoot}${path.enroll}`}
            element={withSuspanse(<AuthorizeSponsor><AccountContainer /></AuthorizeSponsor>)}
          />
          <Route
            path={`${path.sponsorRoot}${path.login}`}
            element={withSuspanse(<AuthorizeSponsor><LoginAccountContainer /></AuthorizeSponsor>)}
          />
          <Route path={path.notFound} element={<NotFound is404 />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default RouteManager;
