import React, { createContext, useState, useContext } from 'react';
import { KCP_TOKEN } from '../constants/constants';
import { pathConfig } from '../data/appURL';
import instance from '../data/axios';
import handleAPIRequest from '../data/requests';
import { useAppStatus } from './useAppStatus';

const authContext = createContext();
// Provider hook that creates auth object and handles state

function useProvideAuth() {
  const [user, setuser] = useState(null);
  const [appStatus, setAppStatus] = useAppStatus();
  const login = async (email, password) => {
    // Do auth request;
    try {
      const response = await handleAPIRequest(pathConfig.LOGIN, { email, password }, true);
      if (!response) {
        throw new Error();
      }
      setuser(response);
      const authToken = response?.data?.auth_token;
      instance.defaults.headers.common.Authorization = authToken;
      setAppStatus({ ...appStatus, [KCP_TOKEN]: authToken });
      return response;
    } catch {
      // error
    }
    return true;
  };

  const logout = () => {
    setuser(null);
    return true;
  };

  return {
    user,
    login,
    logout,
  };
}

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
// eslint-disable-next-line react/prop-types
export function AuthProvider({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => useContext(authContext);
