import { useEffect } from 'react';
import ReactGA from 'react-ga4';

const GAID = process.env.REACT_APP_GA4ID;
if (!GAID) {
  const message = `Google Analytics malfunction:\nTracking ID:${GAID}`;
  console.error(message); // eslint-disable-line no-console
}

ReactGA.initialize(GAID);

const useAnalytics = (location) => {
  useEffect(() => {
    console.log('attempting to send pageview'); // eslint-disable-line no-console
  }, [location]);
};

export default useAnalytics;
