import axios from 'axios';
import { BASE, ENDPOINT } from './appURL';

/**
 * Base URL to perform requests
 */
const instance = axios.create({
  baseURL: `${ENDPOINT}${BASE}`,
});

export default instance;
