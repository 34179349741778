import React from 'react';
import {
  createTheme,
  ThemeProvider as Provider,
  useTheme,
} from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';

const themeObj = {
  palette: {
    background: {
      default: '#F2F5F3',
      paper: '#fff',
    },
    text: {
      primary: '#142A39',
      secondary: '#142A39',
    },
    neutral: '#BABFBD',
    primary: {
      main: '#067F6F',
    },
    input: {
      backgroundColor: '#fff',
    },
    alert: {
      info: {
        border: '#FDC246',
        backgroundColor: '#FFFAF0',
      },
      error: {
        border: '#CC3D3F',
        backgroundColor: '#F9E8E8',
      },
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: 'Poppins, Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,sans-serif',
    h1: {
      fontWeight: 'bold',
      fontSize: '40px',
      lineHeight: '48px',
      letterSpacing: '-0.008em',
    },
    h2: {
      fontWeight: 'bold',
      fontSize: '27px',
      lineHeight: '33px',
      letterSpacing: '0.25px',
    },
    h4: {
      fontWeight: 'bold',
      fontSize: '28px',
      lineHeight: '30px',
    },
    h5: {
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: '30px',
    },
    h6: {
      fontWeight: '600',
      fontSize: '18px',
      lineHeight: '27px',
      letterSpacing: '0.25px',
    },
    body1: {
      lineHeight: '22px',
      fontFamily: 'Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,sans-serif',
      marginTop: '4px',
    },
  },
  colors: {
    backgroundColor: '#E5E5E5',
    link: {
      primary: '#067F6F',
    },
  },
};
const desktopTheme = createTheme(themeObj);
const mobileTheme = createTheme({
  ...themeObj,
  typography: {
    fontFamily: 'Poppins, Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,sans-serif',
    h1: {
      fontWeight: 'bold',
      fontSize: '32px',
      lineHeight: '40px',
      letterSpacing: '0.2%',
    },
    h2: {
      fontWeight: 'bold',
      fontSize: '27px',
      lineHeight: '33px',
      letterSpacing: '0.25px',
    },
    h3: {
      fontSize: '26px',
      fontWeight: 700,
      lineHeight: '39px',
      letterSpacing: '0.002em',
      textAlign: 'center',
    },
    h4: {
      fontWeight: 'bold',
      fontSize: '28px',
      lineHeight: '30px',
    },
    h5: {
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: '30px',
    },
    h6: {
      fontWeight: '600',
      fontSize: '18px',
      lineHeight: '27px',
      letterSpacing: '0.25px',
    },
    body1: {
      fontSize: '18px',
      lineHeight: '21px',
      letterSpacing: '-0.2px',
      fontFamily: 'Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,sans-serif',
    },
  },
});

const ThemeProvider = ({ children }) => {
  const defaultTheme = useTheme();
  const isDeskTopViewPort = useMediaQuery(defaultTheme.breakpoints.up('md'));
  const theme = isDeskTopViewPort ? desktopTheme : mobileTheme;
  return <Provider theme={theme}>{children}</Provider>;
};

export default ThemeProvider;

ThemeProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
