import React from 'react';
import { AuthProvider } from './hooks/useAuth';
import RouteManager from './routes/RouteManager';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import { AppStatusProvider } from './hooks/useAppStatus';

function App() {
  return (
    <ErrorBoundary>
      <AppStatusProvider>
        <AuthProvider>
          <RouteManager />
        </AuthProvider>
      </AppStatusProvider>
    </ErrorBoundary>
  );
}

export default App;
