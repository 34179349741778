import React, { Suspense } from 'react';

const withSuspanse = (wrappedComponent) => (
  <Suspense
    fallback={(
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        Loading
      </div>
     )}
  >
    {wrappedComponent}
  </Suspense>
);

export default withSuspanse;
