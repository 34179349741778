export const KCP_TOKEN = 'KCP_USER_TOKEN';
export const SUBSCRIPTION_TOEKN = 'KCP_USER_SUBSCRIPTION_TOKEN';
export const CUSTOMER_SUPPORT = {
  PHONE: '(855)-958-5577',
  EMAIL: 'kcpsupport@alivecor.com',
};
export const TELEHEALTH_INFORMED_CONSENT = 'https://www.kardia.com/telehealth-informed-consent/';
export const TERMS_OF_SERVICE = 'https://www.kardia.com/tos/';
export const PRIVACY_POLICY = 'https://www.kardia.com/privacy/';
export const SOCIAL_LINKS = {
  FACEBOOK: 'https://www.facebook.com/AliveCor/',
  TWITTER: 'https://www.twitter.com/alivecor',
  INSTAGRAM: 'https://www.instagram.com/alivecor_inc/',
  LINKEDIN: 'https://www.linkedin.com/company/alivecor/',
};
export const KARDIA_WEBSITE_URL = 'https://www.kardia.com';

export const ELIGIBLE_STATES = [
  'CA', // California
  'TX', // Texas
  'FL', // Florida
  'IL', // Illinois
  'GA', // Georgia
  'IN', // Indiana
  'WA', // Washington
  'MS', // Mississippi
  'NY', // New York
  'NV', // Nevada
  'KY', // Kentucky
  'NH', // New Hampshire
  'TN', // Tennessee
  'OR', // Oregon
  'MO', // Missouri
  'OK', // Oklahoma
  'AL', // Alabama
  'MI', // Michigan
  'MN', // Minnestota
  'AZ', // Arizona
  'PA', // Pennsylvania
  'NJ', // New Jersey
  'OH', // Ohio
  'NC', // North Carolina
  'VA', // Virgina
  'MD', // Maryland
  'WI', // Wisconsin
  'IA', // Iowa
  'WV', // West Virginia
  'DE', // Deleware
  'ME', // Maine
  'ND', // North Dakota
  'UT', // Utah
  'CO', // Colorado
  'LA', // Louisiana
  'HI', // Hawaii
  'MT', // Montana
  'VT', // Vermont
  'DC', // District of Columbia
  'WY', // Wyoming
  'MA', // Massachusetts
];
