import {
  BASE, BASE_RAILS, ENDPOINT, ENDPOINT_RAILS,
} from './appURL';
import instance from './axios';

function errorHandler(error) {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    return {
      error: {
        ...error.response.data,
        status: error.response.status,
      },
    };
  }
  if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    return {
      error: {
        status: 421,
      },
    };
  }
  // Something happened in setting up the request that triggered an Error
  // console.log('Error', error.message);
  return {
    error: {
      status: 1,
    },
  };
}

export function getURL(pathName, isRails) {
  return (isRails ? `${ENDPOINT_RAILS}${BASE_RAILS}${pathName}` : `${ENDPOINT}${BASE}${pathName}`);
}

export default async function handleAPIRequest(config, data, isRails = false) {
  try {
    const { path, method } = config;
    const response = await instance({
      method,
      url: getURL(path, isRails),
      [method === 'GET' ? 'params' : 'data']: data,
    });
    return response;
  } catch (error) {
    return errorHandler(error);
  }
}
