import React from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { ReactComponent as Logo } from '../../assets/alivecor-logo.svg';
import BulletPoint from '../BulletPoint/BulletPoint';
import CustomLink from '../common/CustomLink/CustomLink';
import SocialIconBar from '../SocialIconBar/SocialIconBar';
import {
  CUSTOMER_SUPPORT, KARDIA_WEBSITE_URL,
  PRIVACY_POLICY, TELEHEALTH_INFORMED_CONSENT,
  TERMS_OF_SERVICE,
} from '../../constants/constants';

const useStyles = makeStyles((theme) => ({
  footerMega: {
    padding: '2rem 0',
    borderTop: `4px solid ${theme.palette.primary.main}`,
    [theme.breakpoints.up('md')]: {
      padding: '3rem 0',
    },
  },
  footer: {
    borderTop: `4px solid ${theme.palette.primary.main}`,
    padding: '1rem',
    [theme.breakpoints.up('md')]: {
      '& > p': {
        maxWidth: '70%',
        padding: '36px 64px',
      },
    },
    [theme.breakpoints.up('lg')]: {
      '& > p': {
        maxWidth: '50%',
      },
    },
  },
}));

const Footer = ({ showMegaFooter }) => {
  const classes = useStyles();
  return (
    <footer className={showMegaFooter ? classes.footerMega : classes.footer}>
      {showMegaFooter ? (
        <Container maxWidth="lg">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            rowGap={8}
          >
            <Grid
              item
              lg={5}
              container
              direction="column"
              justifyContent="space-between"
              rowGap={2}
            >
              <BulletPoint
                title="Customer Care"
                description={(
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box>
                      Phone:
                      {' '}
                      <CustomLink
                        url={`tel:${CUSTOMER_SUPPORT.PHONE}`}
                        text={CUSTOMER_SUPPORT.PHONE}
                        fontwight={400}
                        native
                      />
                    </Box>
                    <Box>
                      Email:
                      {' '}
                      <CustomLink
                        url={`mailto:${CUSTOMER_SUPPORT.EMAIL}`}
                        text={CUSTOMER_SUPPORT.EMAIL}
                        underline
                        primary
                        fontwight={400}
                        native
                      />
                    </Box>
                  </Box>
                )}
                variant="h6"
                bullet={false}
              />
              <CustomLink
                text="Telehealth Informed Consent"
                underline
                url={TELEHEALTH_INFORMED_CONSENT}
                native
                target="_blank"
              />
              <CustomLink
                text="Terms & Conditions"
                underline
                url={TERMS_OF_SERVICE}
                native
                target="_blank"
              />
              <CustomLink
                text="Privacy Policy"
                underline
                url={PRIVACY_POLICY}
                native
                target="_blank"
              />
            </Grid>
            <Grid
              item
              lg={2}
              container
              direction="column"
              justifyContent="space-between"
              alignItems="center"
            >
              <Logo />
              <Grid
                item
                container
                lg={8}
                direction="column"
                justifyContent="flex-end"
                alignItems="center"
              >
                <CustomLink text="www.kardia.com" url={KARDIA_WEBSITE_URL} target="_blank" primary native />
                <SocialIconBar />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <Typography>
          ©2021 AliveCor, Inc. All Rights Reserved. US Patent Nos 8,301,232,
          8,509,882 and Patents Pending. AliveCor and Kardia are trademarks of
          AliveCor, Inc. in the United States and other countries.
        </Typography>
      )}
    </footer>
  );
};

export default Footer;

Footer.propTypes = {
  showMegaFooter: PropTypes.bool.isRequired,
};
