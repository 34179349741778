import React from 'react';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  bulletContainer: {
    display: 'inline-block',
    padding: '0 2rem',
    margin: '0',
    marginLeft: '2rem',
    position: 'relative',
    '& > li::marker': {
      fontSize: '2em',
      color: theme.colors.link.primary,
    },
  },
  noBullet: {
    padding: '1rem 0',
    margin: '0px',
    '& > li': {
      listStyle: 'none',
    },
  },
}));
const BulletPoint = ({
  title, description, bullet = true, variant = 'h5',
}) => {
  const classes = useStyles();
  return (
    <ul className={bullet ? classes.bulletContainer : classes.noBullet}>
      <li>
        <Typography variant={variant}>{title}</Typography>
        {
          typeof description === 'string' ? <Typography variant="body1">{description}</Typography> : description
        }
      </li>
    </ul>
  );
};

export default BulletPoint;

BulletPoint.defaultProps = {
  bullet: true,
  variant: 'h5',
};

BulletPoint.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  bullet: PropTypes.bool,
  variant: PropTypes.string,
};
