// Authentication config;

import React, { createContext, useContext, useState } from 'react';

const appStatusContext = createContext();
export function useProvideAppStatus() {
  const [status, setStatus] = useState({ sponsor: 'omron', appLoading: false });
  const setAppStatus = (value) => {
    setStatus(value);
  };
  return [status, setAppStatus];
}
// Provider hook that creates auth object and handles state
// eslint-disable-next-line react/prop-types
export function AppStatusProvider({ children }) {
  const status = useProvideAppStatus();
  return (
    <appStatusContext.Provider value={status}>
      {children}
    </appStatusContext.Provider>
  );
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAppStatus = () => useContext(appStatusContext);
