/* eslint-disable */
import path from '../routes/constants';

// Geting the search param values from the given search queryParams
export const deleteParamValue = (param, params) => {
  const urlParams = new URLSearchParams(params);
  urlParams.delete(param);
  window.history.replaceState(
    {},
    '',
    `${window.location.pathname}?${urlParams}${window.location.hash}`
  );
};

// Geting the search param values from the given search queryParams
export const getParamValue = (param, params) => {
  const urlParams = new URLSearchParams(params);
  return urlParams.get(param);
};

// extract the rule from string and validate
const extractRuleAndValidate = (state, validationRule, value) => {
  const [operation, targetField] = validationRule.split(' ');
  if (operation === 'match') {
    return state[targetField].value === value;
  }
  return false;
};

// RFC 3339 time format
export function toISODateString(d) {
  function pad(n) {
    return n < 10 ? `0${n}` : n;
  }
  return `${d.getUTCFullYear()}-${pad(d.getUTCMonth() + 1)}-${pad(
    d.getUTCDate()
  )}T${pad(d.getUTCHours())}:${pad(d.getUTCMinutes())}:${pad(
    d.getUTCSeconds()
  )}Z`;
}

const sanitizeValue = (fieldName, formState) => {
  const { value } = formState[fieldName];
  if (typeof formState[fieldName].value === 'boolean') {
    return {
      [fieldName]: value,
    };
  }
  if (fieldName === 'height') {
    return {
      [fieldName]: Number.parseFloat(value),
    };
  }
  // sending null for I don't want to say as per BE API requirement
  if (fieldName === 'sex') {
    return {
      [fieldName]: value === "I don't want to say" ? null : value,
    }
  }
  // sending null for '' as per BE API requirement
  if (fieldName === 'race') {
    return {
      [fieldName]: value === '' ? null : value,
    }
  }
  if (fieldName === 'dateOfBirth') {
    const isISOString = formState[fieldName]?.isISOString;
    const date = new Date(value);
    const day = (date.getDate() > 9) ? date.getDate() : ('0' + date.getDate());
    const month = (date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1));
    const year = date.getFullYear();
    const dateString =  `${year}-${month}-${day}`;
    return {
      [fieldName]: isISOString ? new Date(dateString).toISOString() : dateString,
    };
  }
  return {
    [fieldName]: value,
  };
};

// Checking if the object is having non empry property values or not
const validateObject = (obj) => Object.values(obj).every((val) => val !== '');

// select validate method based on validation rule
const validator = (value, validationRule, state) => {
  switch (typeof validationRule) {
    case 'string':
      return extractRuleAndValidate(state, validationRule, value);
    case 'function':
      return validationRule(value);
    default:
      return validationRule.test(value);
  }
};

const inValidateConfirmPasswordField = (password, state, setState, isValidInput) => {
  // if password is valid and password is not matching with confirm password then confirm password should be invalid
  const fieldName = 'confirmPassword';
  const confirmPasswordState = state[fieldName];
  const confirmPassword = confirmPasswordState.value;
    setState((prevState) => ({
      ...prevState,
      [fieldName]: {
        ...state[fieldName],
        valid: (isValidInput && password !== confirmPassword) ? false : true,
      },
    }));
}
// input validation function based on validation rule or required
export const validate = (state, target, setState) => {
  const fieldName =  target.name;
  const { validationRule, required } = state[fieldName];
  const { value, type } = target;
  let isValidInput = true;
  if (validationRule) {
    isValidInput = validator(value, validationRule, state);
  } else if (required) {
    if (type === 'checkbox') {
      isValidInput = target.checked;
    } else {
      isValidInput =
        typeof value === 'object' ? validateObject(value) : value.trim() !== '';
    }
  }
  return isValidInput;
};

// Check if the form is having valid inputs
export const isFormValid = (formState) =>
  Object.keys(formState).every((key) => {
    const { valid, required, isTouched } = formState[key];
    if (required) {
      return valid && isTouched;
    }
    return valid;
  });

// get the errors
export const getErrorState = (formState) => {
  const copy = { ...formState };
  Object.keys(formState).forEach((key) => {
    if (!copy[key].valid) {
      copy[key].isTouched = true;
    }
  });
  return copy;
};

export const updateErrorsState = (formState, errors) => {
  const tempState = { ...formState };
  errors.forEach((error) => {
    tempState[error.fieldName] = {
      ...tempState[error.fieldName],
      valid: false,
      message: error.message,
    };
  });
  return tempState;
};

const toCMS = (value) => {
  const { feet, inches } = value;
  // 1 feet = 12 inches
  // 1 inches = 2.54 cms
  return +((+feet * 12 + +inches) * 2.54);
};

const getValue = (target) => {
  const { name, type, value } = target;
  if (name === 'height') return toCMS(value);
  return type === 'checkbox' ? target.checked : target.value;
};

export const handleChange = (target, state, setState, initialMessage = '') => {
  const fieldName = target.name;
  const valid = validate(state, target, setState);
  const value = getValue(target);
  setState((prevState) => ({
    ...prevState,
    [fieldName]: {
      ...state[fieldName],
      isTouched: true,
      value,
      valid,
      message: initialMessage,
    },
  }));
  if (fieldName === 'password') {
    inValidateConfirmPasswordField(value, state, setState, valid);
  }
};

/**
 * check if the user's age is above X yearns
 * @param {string} dob
 * @returns boolean
 */
const isXplus =
  (X) =>
  (dob = new Date()) => {
    if (!dob) return false;
    const dateOfBirth = new Date(dob);
    const y1 = dateOfBirth.getFullYear();
    const currentDate = new Date();
    const y2 = currentDate.getFullYear();
    return y2 - y1 >= X;
  };

export const is18plus = (dob) => isXplus(18)(dob);

export const generateMenuOptionsInRage = (start, end) => {
  const menu = [];
  for (let i = start; i <= end; i += 1) {
    menu.push({ name: i, value: i });
  }
  return menu;
};

/**
 * If the value(adddress) contains any one of the POBox cases make it invalid
 * or if it doesn't have POBOX then check for empty string.
 * @param {string} value
 * @returns boolean
 */
export const validatePOBox = (value) => {
  const CASES = [
    'po box',
    'pobox',
    'p.o.box',
    'po. box',
    'p.o. box',
    'p. o. box',
    'p.o box',
    'p.o. box',
    'post office box',
    'apo',
    'Army Post Office',
    'Army PO',
    'fpo',
    'Fleet Post Office',
  ];
  let valid = false;
  const hasPOBox = CASES.some(
    (keyword) => value && value.toLowerCase().includes(keyword.toLowerCase())
  );
  if (hasPOBox) {
    valid = false;
  } else {
    valid = !!value;
  }
  return valid;
};
/**
 * convert object to comma seperated string
 * @param {Object} obj
 * @returns
 */
export const toString = (obj) =>
  Object.values(obj)
    .filter((val) => !!val)
    .reduce((cur, acc) => `${cur}, ${acc}`);
export const checkValidSponsor = (sponsor) => {
  const validSponsors = ['omron'];
  return !!validSponsors.find(
    (validSponsor) => validSponsor?.toLowerCase() === sponsor?.toLowerCase()
  );
};


export const canShowMegaFooter = (pathname, sponsor) =>
  checkValidSponsor(sponsor) &&
  (pathname === path.root ||
    pathname.endsWith(path.success) ||
    pathname.endsWith(`${path.success}/`) ||
    pathname.endsWith(`/${sponsor}`) ||
    pathname.endsWith(`/${sponsor}/`));

// Gettiung form data object with field name and value pair
const getFormData = (formState) =>
  Object.keys(formState).reduce((acc, cur) => {
    const keyValuePair = sanitizeValue(cur, formState);
    return { ...acc, ...keyValuePair };
  }, {});
export default getFormData;
