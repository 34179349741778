import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { ReactComponent as Logo } from '../../assets/alivecor-logo.svg';
import CTA from '../common/CTA/CTA';
import path from '../../routes/constants';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    minHeight: '80px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      minHeight: '96px',
    },
  },
  logo: {
    margin: '0 auto',
  },
}));

const NavBar = (props) => {
  const { showBackBtn, sponsor } = props;
  const classes = useStyles();
  return (
    <Container maxWidth="lg">
      <nav className={classes.toolbar}>
        {showBackBtn && (
          <CTA
            icon={<ArrowBackIosIcon />}
            iconPosition="start"
            to={`/${sponsor}` || path.root}
          >
            <Typography variant="button" fontWeight={700}>
              Back to program details
            </Typography>
          </CTA>
        )}
        <div className={classes.logo}>
          <Link to={path.root}>
            <Logo />
          </Link>
        </div>
      </nav>
    </Container>
  );
};
export default NavBar;

NavBar.defaultProps = {
  showBackBtn: false,
};
NavBar.propTypes = {
  showBackBtn: PropTypes.bool,
  sponsor: PropTypes.string.isRequired,
};
