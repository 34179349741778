import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { useLocation } from 'react-router-dom';
import useAnalytics from '../../hooks/useAnalytics';
import NavBar from '../../components/NavBar/NavBar';
import Footer from '../../components/Footer/Footer';
import { canShowMegaFooter, checkValidSponsor } from '../../utils';

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    minHeight: '74vh',
    padding: '2rem 0 10rem 0',
  },
}));

const Layout = ({ children }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const sponsorRoot = pathname.split('/');
  const sponsor = sponsorRoot[1];
  const showMegaFooter = canShowMegaFooter(pathname, sponsor);
  const showBackBtn = checkValidSponsor(sponsor) && (pathname.endsWith('assessment') || pathname.endsWith('assessment/'));
  useAnalytics(pathname);
  return (
    <>
      <NavBar showBackBtn={showBackBtn} sponsor={sponsor} />
      <main className={classes.main}>{children}</main>
      <Footer showMegaFooter={showMegaFooter} />
    </>
  );
};
export default Layout;
Layout.propTypes = {
  children: PropTypes.element.isRequired,
};
