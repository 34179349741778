import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100vw - 2rem)',
    },
  },
  custom: {
    color: `${theme.palette.primary.main}!important`,
    border: '1px solid rgba(6, 127, 111, 0.5)!important',
    [theme.breakpoints.down('sm')]: {
      background: `${theme.palette.primary.main}!important`,
      color: '#fff!important',
    },
  },
}));
const CTALink = ({ to, children }) => (
  <Link to={to} style={{ textDecoration: 'none', margin: '1rem 0' }}>
    {children}
  </Link>
);

CTALink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
CTALink.defaultProps = {
  to: '/',
};

const CTA = ({
  to, variant, icon, iconPosition, children,
}) => {
  const classes = useStyles();
  let button = <Button classes={classes} variant={variant}>{children}</Button>;
  if (variant === 'custom') {
    button = <Button classes={classes} className={classes.custom}>{children}</Button>;
  }
  if (icon && iconPosition === 'start') {
    button = (
      <Button variant={variant} startIcon={icon} classes={classes}>
        {children}
      </Button>
    );
  } else if (icon && iconPosition === 'end') {
    button = (
      <Button variant={variant} endIcon={icon} classes={classes}>
        {children}
      </Button>
    );
  }
  return <CTALink to={to}>{button}</CTALink>;
};

CTA.propTypes = {
  to: PropTypes.string,
  variant: PropTypes.string,
  icon: PropTypes.element,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  iconPosition: PropTypes.oneOf(['start', 'end']),
};
CTA.defaultProps = {
  to: '/',
  variant: 'outlined',
  icon: null,
  iconPosition: 'start',
};

export default CTA;
